<template>

  <div
    class="card"
    id="print-pharmacy"
    style="border: none"
  >
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <table>
            <div style="font-size: 14pt">Invoice</div>
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.code }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.display_transaction_date }}</td>
            </tr>
            <tr v-if="data.patient_name != ''">
              <td>Nama Pasien</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.doctor_name != ''">
              <td>Dokter</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <img
            src="/images/hayandra.jpeg"
            height="60"
          >
          <div
            class="font-weight-light"
            style="font-size: 10.7px"
          >{{ setting.address ? setting.address : ""  }}</div>
          <div
            class="font-weight-light"
            style="font-size: 10.7px"
          >{{ setting.phone ? setting.phone : "" }}</div>
        </div>
      </div>

      <!-- <div
        class="row justify-content-center"
        style="font-size: 12px;"
      >
        <div class="col-md-6">
          <table class="font-weight-bold">
            <tr v-if="data.patient_name != ''">
              <td style="width: 120px; padding: 5px">Nama Pasien</td>
              <td>: {{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.doctor_name != ''">
              <td style="width: 120px; padding: 5px">Dokter</td>
              <td>: {{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6">
          <table class="font-weight-bold">
            <tr v-if="data.patient_name != ''">
              <td style="width: 120px; padding: 5px">Nama Pasien</td>
              <td>: {{ data.patient_name }}</td>
            </tr>
          </table>
        </div>
      </div> -->
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
        <template #cell(amount)="data">
          {{ 'Rp ' + parseInt(data.item.amount).toLocaleString('id-ID') }}
        </template>
        <template #cell(total)="data">
          {{ 'Rp ' + parseInt(data.item.amount * data.item.quantity).toLocaleString('id-ID') }}
        </template>
      </b-table>
      <div class="mt-3 d-flex justify-content-between align-items-center">
        <div>
          <!-- Notes -->
          <div class="form-group">
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="data.notes"
              readonly
            ></textarea>
          </div>
        </div>
        <table>
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">{{ parseInt(data.total_amount).toLocaleString('id-ID') }}</td>
          </tr>
          <tr>
            <td style="width: 150px">Biaya Tambahan</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">{{ parseInt(data.surcharge).toLocaleString('id-ID') }}</td>
          </tr>
          <tr>
            <td style="width: 150px">Total Keseluruhan</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">{{ parseInt(data.final_amount).toLocaleString('id-ID') }}</td>
          </tr>
          <template v-if="data.pharmacy_payment_method_id == 1">
            <tr>
              <td>
                <hr style="margin: 5px">
              </td>
              <td>
                <hr style="margin: 5px">
              </td>
              <td>
                <hr style="margin: 5px">
              </td>
            </tr>
            <tr>
              <td style="width: 150px">Uang</td>
              <td class="font-weight-bold">Rp</td>
              <td class="text-right font-weight-bold">{{ parseInt(data.payment_amount).toLocaleString('id-ID') }}</td>
            </tr>
            <tr>
              <td style="width: 150px">Kembalian</td>
              <td class="font-weight-bold">Rp</td>
              <td class="text-right font-weight-bold">{{ parseInt(data.change_amount).toLocaleString('id-ID') }}</td>
            </tr>
          </template>
          <tr>
            <td style="width: 150px">Pembayaran</td>
            <td style="width: 25px"></td>
            <td class="text-right font-weight-bold">{{ data.payment_method_name }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
export default {

  props: {
    data: Object,
    items: Array,
    setting: Object
  },

  data() {
    return {
      fields: [
        {
          key: "medicine_name",
          label: 'Obat',
        },
        {
          key: "quantity",
          label: 'Jumlah',
        },
        {
          key: "amount",
          label: 'Harga Satuan',
        },
        {
          key: "quantity",
          label: 'Jumlah',
        },
        {
          key: "total",
          label: 'Total',
        },
      ],
    }
  }

}
</script>

<style>
</style>